<template>
  <div class="schedule">
    <div class="row">
      <div class="col-md-3 frm-l">
        <label class="lbl">
          {{ $t("lesson.form.optional-items") }}
        </label>
      </div>
      <div class="col-md-9 frm-r">
        <table class="table">
          <tbody>
            <tr v-for="(item, index) in optionalItems" :key="index">
              <td>
                <Input
                  type="text"
                  label="オプション名"
                  :hideLabel="true"
                  name="name"
                  placeholder="オプション名"
                  rules="required|max:100"
                  v-model="item.name"
                  @input="value => hanleChangeItems(index, 'name', value)"
                />
              </td>
              <td>
                <Input
                  type="number"
                  label="価格"
                  :hideLabel="true"
                  name="price"
                  rules="required|min_value:100"
                  minValue="0"
                  placeholder="価格"
                  v-model="item.price"
                  @input="value => hanleChangeItems(index, 'price', value)"
                />
              </td>
              <td>
                <button 
                    type="button" 
                    class="btn btn-sm btn-danger"
                    @click="removeItems(index)"
                    >X</button>
              </td>
            </tr>
          </tbody>
        </table>
        <button 
            type="button" 
            class="btn btn-success w-100"
            @click="addMore"
        >
          ＋オプションを追加（最大10件）
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "LessonOptionalItems",
  components: {},
  data() {
    return {
      optionalItems: [],
      isEdit: false,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    removeItems(index) {
      let obj = this.optionalItems[index];
      if (obj.id) {
        this.$emit("deleteIds", obj.id);
      }
      this.optionalItems.splice(index, 1);
      this.$emit("change", this.optionalItems);
    },
    hanleChangeItems(index, name, value){
      if(name == "price" && value < 0){
        value = 0;
      }
      this.optionalItems[index][name] = value;
      this.$emit("change", this.optionalItems);
    },
    addMore(){
        if(this.optionalItems.length < 10) {
            this.optionalItems.push({
                name: "",
                price: "",
            })
        }
    }
  },
  watch: {
    items: function (newValue, oldValue) {
      if (newValue !== this.optionalItems) {
        this.optionalItems = newValue;
      }
    },
  },
};
</script>
